import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ITimeSheetStatusEntity } from '../../interfaces/api/time-sheet-status-entity';

export enum TimeSheetStatusSignalTypes {
  LOAD_TIME_SHEET_STATUSES = '[TimeSheetStatus][Signal] Load Collection',
}

export enum TimeSheetStatusMessageTypes {
  SET_COLLECTION = '[TimeSheetStatus][Message] Set Collection',
  ADD_ONE = '[TimeSheetStatus][Message] Add One',
  UPDATE_ONE = '[TimeSheetStatus][Message] Update One',
  UPSERT_ONE = '[TimeSheetStatus][Message] Upsert One',
  DELETE_ONE = '[TimeSheetStatus][Message] Delete One',
  ADD_MULTIPLE = '[TimeSheetStatus][Message] Add All',
  DELETE_MULTIPLE = '[TimeSheetStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[TimeSheetStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[TimeSheetStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ITimeSheetStatusEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ITimeSheetStatusEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ITimeSheetStatusEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ITimeSheetStatusEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ITimeSheetStatusEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ITimeSheetStatusEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ITimeSheetStatusEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = TimeSheetStatusMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type TimeSheetStatusMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
