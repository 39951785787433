import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ITimeSheetStatusEntity } from '../../interfaces/api/time-sheet-status-entity';

export function sort(a: ITimeSheetStatusEntity, b: ITimeSheetStatusEntity): number {
  return a.order - b.order;
}

export const adapter: EntityAdapter<ITimeSheetStatusEntity> =
  createEntityAdapter<ITimeSheetStatusEntity>({
    sortComparer: sort,
  });

export const initialState = adapter.getInitialState({});
